<template lang="pug">
div(class='list-filter-container') 
  template(v-if='!breakpoints.md')
    btn(icon='sliders' class='mb-2' block secondary data-test='btn-show-filters' @click='drawerVisible = !drawerVisible')
      span {{ $t('show filters') }}
      pill(v-if='selectedFiltersNum && selectedFiltersNum > 0' class='ml-2' type='secondary') {{ selectedFiltersNum }}
    btn(v-if='selectedFiltersNum > 0' link icon='x' @click='$emit("clear")') {{ $t('clear') }}
    transition(name='modals')
      modal-container
        modal(v-if='drawerVisible' placement='right' size='sm' mode='drawer' :closeable='true' :width='286' @close='drawerVisible = false')
          modal-header {{ $t('Filters') }}
          div(class='flex w-full flex-col')
            slot 
            sticky-container(v-if='selectedFiltersNum > 0' position='bottom' class='z-50 -mb-4 bg-white')
              btn(link icon='rotate-ccw' @click='$emit("clear")') {{ $t('Clear all') }}
  div(v-else class='mb-4 flex flex-wrap items-center gap-2')
    div(class='flex flex-1 flex-wrap items-center gap-2' data-test='list-filter-container-left')
      label(class='font-default text-base font-normal text-text-light') {{ $t('Filters') }}:
      slot
      btn(v-if='selectedFiltersNum > 0' link icon='rotate-ccw' small data-test='btn-clear-all' @click='$emit("clear")') {{ $t('Clear all') }}
    div(data-test='list-filter-container-right')
      slot(name='settings')
</template>

<script lang="ts">
import ModalContainer from '../ModalContainer.vue'
import { useCustomBreakpoints } from '@/composables/'
import { defineComponent, ref } from 'vue'

const ListFilterContainer = defineComponent({
  components: { ModalContainer },
  props: {
    selectedFiltersNum: { type: Number, required: true },
  },
  setup() {
    return {
      breakpoints: useCustomBreakpoints(),
      drawerVisible: ref(false),
    }
  },
})
export default ListFilterContainer
</script>

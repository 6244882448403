<template lang="pug">
div(v-bind='attributes' class='checkbox-icon-color')
  icon(v-if='value' :name='indeterminate ? "minus" : "check"')
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { shakeFalsy, lightOrDark } from '@/utilities'
import { defineComponent, computed } from 'vue'

const CheckboxColorIcon = defineComponent({
  components: { Icon },
  props: {
    value: Boolean,
    label: String,
    id: String,
    colorId: { type: String, required: true },
    indeterminate: Boolean,
    disabled: Boolean,
  },
  setup(props) {
    return {
      attributes: computed(() =>
        shakeFalsy({
          'data-disabled': props.disabled,
          'data-color': props.id,
          'data-type': lightOrDark(props.colorId),
          style: `color: ${props.colorId}`,
        }),
      ),
    }
  },
})

export default CheckboxColorIcon
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --checkbox-color-border-color: currentColor
  --checkbox-color-bg-color: currentColor
  --checkbox-color-focus-shadow: 0 0 0px 4px rgba(255,255,255,0.75),  0 0 0px 4px var(--checkbox-color-bg-color)
  --checkbox-color-bg-image: none
  --checkbox-color-size: 30px
  --checkbox-color-icon-color: #fff

.checkbox-icon-color
  display: inline-flex
  align-items: center
  justify-content: center
  cursor: pointer
  width: var(--checkbox-color-size)
  height: var(--checkbox-color-size)
  border: 1.5px solid var(--checkbox-color-border-color)
  background-color: var(--checkbox-color-bg-color)
  background-image: var(--checkbox-color-bg-image)
  border-radius: 29px
  background-size: 150%
  background-position: center center
  background-repeat: no-repeat
  .icon
    color: var(--checkbox-color-icon-color)
    stroke-width: 3px
    font-size: 12px
  &:focus-within, &:hover, :focus + label &, button:hover &
    box-shadow: var(--checkbox-color-focus-shadow)
  &[data-disabled]
    --checkbox-color-cursor: unset!importnant
    --checkbox-color-label-color: var(--color-text-light)!important
    --checkbox-color-border-color: var(--color-text-extralight)!important
    --checkbox-color-bg-color: var(--color-text-extralight)!important
    --checkbox-color-focus-shadow: none!important
  &[data-type='light']
    --checkbox-color-icon-color: #000
  &[data-color='multicolour']
    --checkbox-color-bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHqADAAQAAAABAAAAHgAAAADKQTcFAAAAcElEQVRIDWPM2HTsPwOZ4M7kPjJ1MjAwka2TQo2jFlMYgMRrHw1q4sOKQpWjQU1hABKvfTSoiQ8rClUOWFAzfr6VRXa1uPStFtn+HjAfj1pMdpyRqnE0qEkNMbLVjwY12UFHqsbRoCY1xMhWP2BBDQC32QoJSb0nSQAAAABJRU5ErkJggg==")
    --checkbox-color-border-color: transparent
  &[data-color='nature']
    --checkbox-color-bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHqADAAQAAAABAAAAHgAAAADKQTcFAAAAXklEQVRIDe3QQQ3AMAwEQTdsgqskyiRAAihQWgon76Of9ftWluba63mrefM+zbJqtEsY+hgC5rnUuRVcSg0B81zq3AoupYaAeS51bgWXUkPAPJc6t4JLqSFgnv9G/QGDGQRo+jDfgAAAAABJRU5ErkJggg==")
  &[data-color='transparent']
    --checkbox-color-bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHqADAAQAAAABAAAAHgAAAADKQTcFAAAAZElEQVRIDe3VwQ0AIAhDUXAZ9t8GplFHgPbApZ79wTxN9My8Bq6IAEuzA5dkqMEkYD8Xdd+K3ClqErCfi7pvRe5co/b7F3r4qkJTfYuw3Dhce1waPL4rNBA1KjfuRD0mQ4M16gev6wq+PWrXIQAAAABJRU5ErkJggg==")
  &[data-color='beige'],
  &[data-color='weiß'],
  &[data-color='white']
    --checkbox-color-border-color: var(--grey-100)
    --checkbox-color-focus-shadow:  0 0 0px 4px var(--grey-100)
</style>

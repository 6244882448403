<template lang="pug">
div(:disabled='disabled' class='control-range-slider')
  div(ref='element' class='control-range-slider__slider')
  div(class='control-range-slider__inputs')
    div
      input(type='number' :value='localValue[0]' :min='min' :max='localValue[1]' data-test='input-from' @input='updateValueA')
      span {{ suffix }}
    div
      input(type='number' :value='localValue[1]' :min='localValue[0]' :max='max' data-test='input-to' @input='updateValueB')
      span {{ suffix }}
</template>

<script lang="ts">
import { Input, useControl } from './composables'
import rangeSlider from 'range-slider-input'
import { defineComponent, ref, type SetupContext, type PropType } from 'vue'
import { onMounted } from 'vue'
import { watch } from 'vue'

const ControlRangeSlider = defineComponent({
  props: {
    ...Input.props,
    modelValue: { type: Array as PropType<[number, number]>, required: true },
    suffix: { type: String, default: 'cm' },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    step: { type: Number, default: 1 },
  },
  setup(props, context: SetupContext) {
    const {
      value: val,
      classes,
      focus,
      onChange,
      onFocus,
      isEmpty,
    } = useControl<[number, number]>(props, context, props.modelValue)
    const element = ref<null | HTMLElement>(null)

    const localValue = ref(props.modelValue)

    onMounted(() => {
      const instance = rangeSlider(element.value as HTMLElement, {
        min: props.min,
        max: props.max,
        disabled: props.disabled,
        value: props.modelValue,
        onInput: (value) => (localValue.value = value),
        onRangeDragEnd: emitValue,
        onThumbDragEnd: emitValue,
      })
      watch(
        () => val.value,
        (newValue) => instance.value(newValue),
      )
    })
    const emitValue = () => context.emit('update:modelValue', localValue.value)
    return {
      updateValueA: ($event: any) => ((localValue.value[0] = $event.target.value), emitValue()),
      updateValueB: ($event: any) => ((localValue.value[1] = $event.target.value), emitValue()),
      element,
      val,
      localValue,
      isEmpty,
      onFocus,
      classes,
      onChange,
      focus,
    }
  },
})

export default ControlRangeSlider
</script>
<style lang="stylus">
.control-range-slider
  &__inputs
    display flex
    align-items center
    justify-content space-between
    padding-top: 15px
    input
      margin-right 3px
      background: var(--grey-75)
      border-radius: 5px
      padding: 4px 6px
      text-align: center

.range-slider
  touch-action none
  -webkit-tap-highlight-color transparent
  -webkit-user-select none
  user-select none
  cursor pointer
  display block
  position relative
  width 100%
  height 4px
  background #ddd
  border-radius 4px
  .range-slider__thumb
    position absolute
    z-index 3
    top 50%
    width 20px
    height 20px
    transform translate(-50%, -50%)
    border-radius 50%
    background var(--grey-900)
    box-shadow 2px 2px 6px rgba(0,0,0,0.2)
    &:focus-visible
      outline 0
      box-shadow 0 0 0 6px rgba(33, 150, 243, 0.5)
  .range-slider__thumb[data-disabled]
    z-index 2
  .range-slider__range
    position absolute
    z-index 1
    transform translate(0, -50%)
    top 50%
    width 100%
    height 100%
    background var(--grey-900)
  input[type="range"]
    -webkit-appearance none
    pointer-events none
    position absolute
    z-index 2
    top 0
    left 0
    width 0
    height 0
    background-color transparent
  input[type="range"]::-webkit-slider-thumb
    -webkit-appearance none
    appearance none
  input[type="range"]::-moz-range-thumb
    width 0
    height 0
    border 0
  input[type="range"]:focus
    outline 0

.range-slider[data-vertical]
  height 100%
  width 8px
  .range-slider__thumb
    left 50%
  .range-slider__range
    left 50%
    transform translate(-50%, 0)

.range-slider[data-disabled]
  opacity 0.5
  cursor not-allowed
</style>

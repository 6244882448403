<template lang="pug">
list-filter-container( :selectedFiltersNum='selectedFilters.length' @clear='$emit("clearAll")')
  slot
  list-filter(v-if='attributes.brands' v-slot='{change, value, clear}' name='brands' :initialValue='[]'  class='flex-shrink-0' )
    list-filter-dropdown(:value="value.length || false" :disabled='attributes.brands.values.length === 0' :label='$t("Brands")' @clear='clear')
      control-select-list-multi(class='-mx-4 md:mx-0' show-filter-input :options='attributesToOptions(attributes.brands.values)' :modelValue='value' @update:modelValue='change')
  list-filter(v-if='attributes.colors && attributes.colors.values'  v-slot='{change, value, clear}' name='colors' :initialValue='[]'  class='flex-shrink-0' )
    list-filter-dropdown(:value="value.length || false" :disabled='attributes.colors.values.length === 0' :label='$t("Colors")' @clear='clear')
      control-select-list-multi(class='-mx-4 md:mx-0' show-filter-input :options='attributesToOptions(attributes.colors.values)'  :interceptToggle='interceptColorFilterToggle' :modelValue='value || []' @update:modelValue='change') 
        template(#checkbox='{option, selected}')
          checkbox-color-icon(:id='option.value' class='-my-2' :label='option.label' :colorId='option.data.colorCode' :value="selected || hasChildrenSelected(option.value, value)" :indeterminate='hasChildrenSelected(option.value, value)')
        template(#after='{isSelected, option, toggle: toggleLocal, selected}')
          div(v-if='(selected || hasChildrenSelected(option.value, value)) && option.data.children.length' class='flex flex-col -mt-2 mb-2')
            template(v-for='o in attributesToOptions(option.data.children)' :key='o.value')
              control-select-list-item(v-if='counts.colors && counts.colors[o.value] > 0'  class='!py-2 ' @click='() => toggleLocal(o.value, option.value)')
                div(class='flex-1 ml-2') {{ o.label }}
                template(#right='{}')
                  checkbox-color-icon(:id='o.value' class='-my-2 mr-1' :label='option.label' style=' --checkbox-color-size: 20px' :colorId='o.data.colorCode' :value="isSelected(o.value)" )

  list-filter(v-if='attributes.materials'  v-slot='{change, value, clear}' name='materials' :initialValue='[]'  class='flex-shrink-0' )
    list-filter-dropdown(:value="value.length || false" :disabled='attributes.materials.values.length === 0' :label='$t("Materials")' @clear='clear')
      control-select-list-multi(class='-mx-4 md:mx-0' show-filter-input :options='attributesToOptions(attributes.materials.values)' :modelValue='value' @update:modelValue='change')
  list-filters(v-slot='{filters, clearAll, values}' :names='["width","depth","height"]' class='flex-shrink-0' )
    list-filter-dropdown(:value="values.length" :label='$t("Dimensions")' :disabled='totalResults === 0' @clear='clearAll' )
      div(class='my-3 md:px-6 w-full')
        div(class='py-4')
          label(class='pb-4 flex') {{ $t('width') }}:
          control-range-slider(:modelValue='filters.width.value.value.length ? filters.width.value.value.split("-") : [0,1000]' :max='1000' data-test='range-slider-width' @update:modelValue='(value: [number, number]) => (filters.width.change(`${value[0]}-${value[1]}`))')
        div(class='py-4 ')
          label(class='pb-4 flex') {{ $t('depth') }}:
          control-range-slider(:modelValue='filters.depth.value.value.length ? filters.depth.value.value.split("-") : [0,1000]' :max='1000' data-test='range-slider-depth' @update:modelValue='(value: [number, number]) => (filters.depth.change(`${value[0]}-${value[1]}`))')
        div(class='py-4 ')
          label(class='pb-4 flex') {{ $t('height') }}:
          control-range-slider(:modelValue='filters.height.value.value.length ? filters.height.value.value.split("-") : [0,1000]' :max='1000' data-test='range-slider-height' @update:modelValue='(value: [number, number]) => (filters.height.change(`${value[0]}-${value[1]}`))')
  list-filter(v-slot='{change, value, clear}' name='price' :initialValue='""'  class='flex-shrink-0' )
    list-filter-dropdown(data-test='filter-price' :value="value.length ? '1' : false" :label='$t("Price")'  :disabled='totalResults === 0'  @clear='clear')
      div(class='my-3 md:px-6 w-full')
        div(class='py-4')
          label(class='pb-4 flex') {{ $t('Price from to') }}:
          control-range-slider(:modelValue='value.length ? value.split("-") : [0,10000]' :suffix='$t("€")' :max='10000' @update:modelValue='(value: [number, number]) => (change(`${value[0]}-${value[1]}`))')
  template(#settings)
    div(class='flex gap-2 items-center')
      list-filter(v-slot='{change, value}'  name='sort')
        control-select(plain bold  small  style='--control-font-size: 14px' :options='[{value: "_score", label: $t("sort")}, {value: "retailPriceNet_desc", label: $t("retailPriceNet_desc")}, {value: "retailPriceNet_asc", label: $t("retailPriceNet_asc")}, {value: "createdAt_desc", label: $t("createdAt_desc")}]' :modelValue='value' @update:modelValue='change')
      btn-group
        btn(icon='custom-grid-small' secondary small :active='viewSettings === "default"' :data-tooltip='$t("larger images")' @click='viewSettings = "default"')
        btn(icon='custom-grid-large' secondary small :active='viewSettings === "large"' :data-tooltip='$t("smaller images")' @click='viewSettings = "large"')

  </template>

<script setup lang="ts">
import { type SearchAttributeCountMap, attributesToOptions } from '../composables'
import CheckboxColorIcon from '@/components/Control/CheckboxColorIcon.vue'
import ControlRangeSlider from '@/components/Control/ControlRangeSlider.vue'
import ControlSelectListItem from '@/components/Control/ControlSelectListItem.vue'
import ControlSelectListMulti from '@/components/Control/ControlSelectListMulti.vue'
import ListFilterDropdown from '@/components/List/ListFilterDropdown.vue'
import ListFilters from '@/components/List/ListFilters.vue'
import type { ISearchAttributesGroups } from '@/components/List/types'
import { type InterceptToggleFn } from '@/types'
import { useVModel } from '@vueuse/core'
import { intersects, objectify, toggle } from 'radash'
import { type PropType, computed } from 'vue'

const props = defineProps({
  attributes: { type: Object as PropType<ISearchAttributesGroups>, required: true },
  selectedFilters: { type: Array as PropType<any>, required: true },
  viewSettings: String as PropType<'default' | 'large'>,
  totalResults: Number,
  counts: { type: Object as PropType<SearchAttributeCountMap>, required: true },
})
const colorParentChildMap = computed(() =>
  objectify(
    props.attributes['colors'].values,
    (o) => o.id,
    (o) => o.children?.map((c) => c.id) || [],
  ),
)
const colorChildSiblingsMap = computed(() =>
  Object.fromEntries(
    props.attributes['colors'].values.flatMap(
      (p) => p.children?.map((c) => [c.id, p.children?.map((p) => p.id).filter((id) => id !== c.id) || []]) || [],
    ),
  ),
)

const hasChildrenSelected = (id: string, values: string[]) => intersects(colorParentChildMap.value[id], values)
const interceptColorFilterToggle = ((id: string, values: string[], _options, parentId: string) =>
  colorParentChildMap.value[id]
    ? // if parent
      values.includes(id)
      ? // if parent already selected
        toggle(values, id)
      : // if parent not already selected
        toggle(
          values.filter((v) => !colorParentChildMap.value[id].includes(v)),
          id,
        )
    : // if has selected siblings
      intersects(colorChildSiblingsMap.value[id], values)
      ? toggle(values, id)
      : toggle(toggle(values, parentId), id)) as InterceptToggleFn

const viewSettings = useVModel(props, 'viewSettings')
</script>

<template lang="pug">
div(class='control-tree')
  tree-menu(v-slot='{ item, expanded: expandedFromSlot, level }' v-model='expanded' :items='options')
    div(v-if='hide ? hide(item) : true' :value='item.id' :class='{ "control-tree__item--selected": item.id === modelValue }' class='control-tree__item') 
      btn(v-if='item.children.length' faded tiny :icon='expandedFromSlot ? "chevron-down" : "chevron-right"' class='btn-collapse' @click.stop='toggleManually(item.id)')
      slot(:item='item' :onClick='clickItem' :level='level' :selected='item.id === modelValue')
        button(@click='clickItem(item.id)') {{ item.title }}
</template>

<script lang="ts">
import TreeMenu from '@/components/TreeMenu.vue'
import type { INestedOption } from '@/types'
import { flattenTree, type IFlatTreeItemMap } from '@/utilities/TreeUtils'
import { toggle } from 'radash'
import { defineComponent, computed, ref, type PropType } from 'vue'
import { watch } from 'vue'

const ControlTree = defineComponent({
  components: { TreeMenu },
  props: {
    options: { type: Array as PropType<INestedOption[]>, default: () => [] },
    preset: String,
    title: String,
    modelValue: { type: String, required: true },
    hide: Function as PropType<(option: INestedOption) => boolean>,
    selectedOption: Object as PropType<INestedOption>,
  },
  setup(props, context) {
    const optionsAsTreeMap = computed<IFlatTreeItemMap<INestedOption>>(() => flattenTree([...props.options]))
    const expanded = ref<string[]>(props.modelValue ? [props.modelValue] : [])
    watch(
      () => props.modelValue,
      () => {
        expanded.value = props.modelValue ? [...optionsAsTreeMap.value[props.modelValue].parents, props.modelValue] : []
      },
    )
    return {
      toggleManually: (id: string) => (expanded.value = toggle(expanded.value, id)),
      clickItem: (id: string) => {
        const isAlreadyActive = props.modelValue === id
        context.emit('update:modelValue', isAlreadyActive ? '' : id)
      },
      expanded,
      optionsAsTreeMap,
    }
  },
})

export default ControlTree
</script>

<style lang="stylus">
.control-tree
  &__item
    display: flex
    position: relative
    align-items: center
    .btn-collapse
      position: absolute
      left: -23px
      font-size: 12px
      stroke-width: 2.5px
    line-height: 1.5
    &--selected
      font-weight: bold
      .icon
        color: var(--grey-900)
</style>

<template lang="pug">
ul(class='tree-menu tree-menu')
  li(v-for='(item, index) in items' :key='item.id')
    slot(:item='item' :level='level' :toggle='() => toggleValue(item.id)' :index='index' :expanded='value.includes(item.id)')
    tree-menu(v-if='value.includes(item.id)' v-model='value' :items='item.children' :level='level + 1')
      template(#default='slotData')
        slot(name='default' v-bind='slotData')
</template>

<script setup lang="ts">
import type { INestedOption } from '@/types'
import { toggle } from 'radash'
import { type PropType } from 'vue'

defineProps({
  items: { type: Array as PropType<INestedOption[]>, default: () => [] },
  modelValue: { type: Array as PropType<string[]>, default: () => [] },
  level: { type: Number, default: 0 },
})

const value = defineModel<string[]>({ default: () => [] })

const toggleValue = (val: string) => (console.log(val), toggle(value.value, val))
</script>

<style lang="stylus">
.tree-menu
  padding-left: 0
  list-style: none
  li
    padding: 0
  & &
    margin-top: 0
    padding-left: 15px
</style>

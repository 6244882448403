<template lang="pug">
slot(:filters='filters' :clearAll='clearAll' :values='values')
</template>

<script lang="ts">
import { useListFilter } from './'
import { type SetPropertiesFunction } from './'
import { mapEntries } from 'radash'
import { defineComponent } from 'vue'
import { type PropType } from 'vue'
import { inject } from 'vue'
import { computed } from 'vue'

const ListFilters = defineComponent({
  props: {
    initialValue: { type: [String, Boolean, Array], default: '' },
    names: { type: Array as PropType<string[]>, required: true },
  },
  setup(props) {
    const setProperties = inject<SetPropertiesFunction>('setProperties')!

    const filters = Object.fromEntries(props.names.map((name) => [name, useListFilter(name, props.initialValue)]))
    const values = computed(() =>
      Object.values(filters)
        .map((filter) => filter.value.value)
        .filter((v) => !!v),
    )
    return {
      values,
      clearAll: () => setProperties(mapEntries(filters, (key) => [key, undefined])),
      filters,
    }
  },
})
export default ListFilters
</script>
